import React, { Suspense } from 'react';
import projectNames from '../src/configs/projectNames';
import skeletonHtml from '../src/skeleton/index.html';

const RootComponent = React.lazy(() => import('./root.component'));

export default function RootLoader(props) {
  return (
    <Suspense
      fallback={
        <div
          className={`${projectNames.namespace}`}
          id={projectNames.packageJsonName}
          dangerouslySetInnerHTML={{ __html: skeletonHtml }}
        />
      }
    >
      <RootComponent {...props} />
    </Suspense>
  );
}
